export default [
  {
    title: 'Permission',
    icon: 'UnlockIcon',
    children: [
      {
        title: 'PermissionRules',
        route: 'permission-rules',
        action: 'read',
        resource: 'Permission',
      },
      {
        title: 'PermissionRoles',
        route: 'permission-roles',
        action: 'read',
        resource: 'Permission',
      },
    ],
  },
]

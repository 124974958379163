<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="totalAmount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header ">
        <h4 class="notification-title mb-1 mr-auto">
          Корзина
        </h4>
        <multi-basket-bar />
        <!-- <b-form-select
          class="mr-1"
          :value="basketIdUsed"
          :options="basketList"
          style="width: 70%;"
          @change="changingBasket"
        />

        <b-button
          v-if="basketCreatedIdUsed === userId && basketDefaultUsed !== 1"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          class="btn-icon rounded-circle"
          @click="removeBasket"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          class="btn-icon rounded-circle"
          @click="createBasket"
        >
          <feather-icon icon="PlusIcon" />
        </b-button> -->

      </div>
    </li>

    <vue-perfect-scrollbar
      v-if="loading"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="i in 2"
        :key="i"
      >
        <template #aside>
          <b-skeleton-img
            height="45px"
            width="62px"
          />
        </template>
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-skeleton width="100%" />
          </h6>
          <small class="cart-item-by"><b-skeleton width="33%" /></small>
        </div>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length && !loading"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.id"
      >
        <template #aside>
          <b-img
            :src="item.file && item.file.path ? item.file.path : '/no-image.png'"
            :alt="item.title"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          v-if="item.createdUserId === userId"
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.id)"
        />
        <feather-icon
          v-if="item.createdUserId === userId && basketCount > 1"
          icon="MoveIcon"
          class="cart-item-remove cursor-pointer"
          style="margin-top: 2.0rem;"
          @click.stop="movingItemFromCart(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.title }}
            </b-link>
          </h6>
          <small class="cart-item-by">{{ item.brand }} / {{ item.articul }}</small>
        </div>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <p
      v-if="!items.length && !loading"
      class="m-0 p-1 text-center"
      style="height: 206px;"
    >
      Корзина пуста
    </p>

    <li
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6
          v-if="totalAmount > 0 && !loading"
          class="font-weight-bolder mb-0"
        >
          Итого:

        </h6>
        <h6
          v-if="totalAmount > 0 && !loading"
          class="text-primary font-weight-bolder mb-0"
        >
          {{ totalAmount }}
        </h6>
        <b-skeleton
          v-if="loading"
          width="20%"
        />
        <b-skeleton
          v-if="loading"
          width="5%"
        />
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'basket' }"
      >
        Перейти в корзину
      </b-button>
    </li>

    <li
      v-if="loading"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <b-skeleton
          width="20%"
        />
        <b-skeleton
          width="5%"
        />
      </div>
    </li>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BImg, BButton, BSkeletonImg, BSkeleton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MultiBasketBar from '@/components/basket/MultiBasketBar.vue'
import { deleteBasketItem, relocateBasketItem } from '@/application/basketService'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BImg,
    VuePerfectScrollbar,
    BButton,
    BSkeletonImg,
    BSkeleton,
    MultiBasketBar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount() {
      return (this.$store.state.basket.basket?.items || []).length
    },
    basketCount() {
      return (this.$store.state.basket.baskets || []).length
    },
    basketSelectList() {
      if ((this.$store.state.basket.baskets || []).length === 0 || ((this.$store.state.basket.baskets || []).length > 0 && this.$store.state.basket.baskets.filter(x => x.id !== this.basketUsed).length === 0)) {
        return {}
      }
      const basket = {};
      (this.$store.state.basket.baskets || []).forEach(x => {
        basket[x.id] = x.title
      })
      return basket
    },
    items() {
      return this.$store.state.basket.basket?.items || []
    },
    userId() {
      return this.$store.state.user.userData?.id || null
    },
  },
  methods: { // /img/3.29c766f8.png
    async fetchItems() {
      try {
        this.loading = true
        await this.$store.dispatch('basket/getbasketUsed')
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Внимание ошибка!',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error ? `${error}`.replace('Error: ', '') : '',
          },
        })
      }
    },
    async removeItemFromCart(id) {
      try {
        const modal = await this.$swal({
          title: 'Подтверждение удаления!',
          text: 'Вы уверены в необходимости удалить элемент? Востановить будет невозможно!',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (modal.value) {
          const search = this.items.find(x => x.id === id && x.createdUserId === this.userId)
          if (!search) throw new Error('У вас нет прав на удаление этой позиции, Вы не являетесь автором ее занесения')

          await deleteBasketItem(id)
          this.$store.commit('app/SET_MESSAGE', 'Товар успешно добавлен.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async movingItemFromCart(id) {
      try {
        const { value: multibasketId } = await this.$swal({
          title: 'Перемещение!',
          text: 'Выберите корзину назначения',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Переместить',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          input: 'select',
          inputPlaceholder: 'Выберите корзину',
          inputValidator: value => new Promise(resolve => {
            if (value) {
              resolve()
            } else {
              resolve('Необходимо выбрать корзину')
            }
          }),
          inputOptions: this.basketSelectList,
        })

        if (multibasketId) {
          const search = this.items.find(x => x.id === id && x.createdUserId === this.userId)
          if (!search) throw new Error('У вас нет прав на перемещение этой позиции, Вы не являетесь автором ее занесения')

          await relocateBasketItem({ id, multibasketId })
          this.$store.commit('app/SET_MESSAGE', 'Товар успешно добавлен.')
        }
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
    .media-body {
      padding-right: 20px;
        .media-heading {
          width: 100% !important;
        }
    }
  }
}
.b-skeleton-text {
  margin-bottom: 0px;
}
</style>

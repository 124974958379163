import { computed } from '@vue/composition-api'
import store from '@/store'

export default computed(() => [
  {
    header: 'User Management',
    action: 'read',
    resource: 'UserManage',
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'user-list',
    action: 'read',
    resource: 'UserManage',
  },
  {
    title: 'Moderation Bonuses',
    icon: 'AwardIcon',
    route: 'moderation-bonuses',
    action: 'read',
    resource: 'UsersMotivation',
    tag: store.state?.dashboard?.userMotivationCreateQuantity > 0 ? store?.state?.dashboard?.userMotivationCreateQuantity : null,
  },
  {
    title: 'Payout',
    icon: 'AwardIcon',
    route: 'payout-list',
    action: 'read',
    resource: 'PayoutManage',
    tag: store.state?.dashboard?.payoutCreateQuantity > 0 ? store?.state?.dashboard?.payoutCreateQuantity : null,
  },
  // {
  //   title: 'Users',
  //   icon: 'UsersIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //     },
  //     {
  //       title: 'View',
  //       route: { name: 'apps-users-view', params: { id: 21 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-users-edit', params: { id: 21 } },
  //     },
  //   ],
  // },
])

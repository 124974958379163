export default [
  {
    header: 'Content Management',
    action: 'read',
    resource: 'ContentHelp',
  },
  {
    title: 'Content Sections',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'ContentHelp',
        route: 'content-help',
        action: 'read',
        resource: 'ContentHelp',
      },
      {
        title: 'ContentAbout',
        route: 'content-about',
        action: 'read',
        resource: 'ContentHelp',
      },
      {
        title: 'PrivacyPolicy',
        route: 'content-privacy-policy',
        action: 'read',
        resource: 'ContentHelp',
      },
    ],
  },
  {
    title: 'Action Management',
    route: 'content-action',
    icon: 'PercentIcon',
    action: 'read',
    resource: 'ActionManage',
  },
  {
    title: 'Product Management',
    route: 'content-product',
    icon: 'FolderIcon',
    action: 'read',
    resource: 'ProductManage',
  },
]

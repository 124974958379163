export default [
  {
    header: 'Application Form Website',
    action: 'read',
    resource: 'ApplicationFormWebsite',
  },
  {
    title: 'Application Form List',
    route: 'application-form-list',
    icon: 'ListIcon',
    action: 'read',
    resource: 'ApplicationFormWebsite',
  },
]

export default [
  {
    header: 'Dashboard',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Dashboard statistics',
    route: 'dashboard',
    icon: 'ServerIcon',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Product statistics',
    route: 'statisticProducts',
    icon: 'ServerIcon',
    action: 'read',
    resource: 'dashboard',
  },
  {
    title: 'Company list',
    route: 'company',
    icon: 'ServerIcon',
    action: 'read',
    resource: 'dashboard',
  },
]

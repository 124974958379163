export default [
  {
    title: 'HomePage',
    icon: 'HomeIcon',
    route: 'main',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'Product',
    icon: 'FolderIcon',
    route: 'product-list',
    action: 'read',
    resource: 'Product',
  },
  {
    title: 'Action',
    icon: 'PercentIcon',
    route: 'action-list',
    action: 'read',
    resource: 'Action',
  },

  {
    title: 'Sales',
    icon: 'ShoppingBagIcon',
    route: 'sale-list',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'Bonus',
    icon: 'BoldIcon',
    route: 'bonus-list',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'UserProfile',
    icon: 'UserIcon',
    route: 'profile-setting',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'HelpContent',
    icon: 'InfoIcon',
    route: 'help',
    action: 'read',
    resource: 'User',
  },
  // {
  //   title: 'UserPayouts',
  //   icon: 'DollarSignIcon',
  //   route: 'user-payout-view',
  //   action: 'read',
  //   resource: 'PayoutManage',
  // },
]

import { computed } from '@vue/composition-api'
import store from '@/store'

export default computed(() => [
  {
    header: 'Sale Management',
    action: 'read',
    resource: 'OrderManage',
  },
  {
    title: 'Ticket Verification Queue',
    route: 'content-ticket-verification-queue',
    icon: 'ServerIcon',
    action: 'read',
    resource: 'OrderManage',
    tag: store.state?.dashboard?.ticketVerificationQuantity > 0 ? store.state?.dashboard.ticketVerificationQuantity : null,
  },
  {
    title: 'Sale List',
    route: 'sale-admin-list',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'OrderManage',
    tag: store.state?.dashboard?.orderCreateQuantity > 0 ? store.state?.dashboard.orderCreateQuantity : null,
  },
])
